import "./style.scss";

import {
  SpaceBetween,
  Spinner,
  StatusIndicator,
} from "@amzn/awsui-components-react-v3";
import Button from "@amzn/awsui-components-react-v3/polaris/button";
import { ExternalSession } from "@amzn/it-support-connect-api-model";
import React, { useEffect, useState } from "react";

import { SystemMessage } from "../types";
import { endSession, fetchExternalSessions, startSession } from "./api";

export interface ContactInfo {
  readonly contactId: string;
  readonly originalContactId: string;
}

interface ExternalSessionPanelProps {
  readonly contactInfo: ContactInfo;
  readonly sendMessage: (
    contactId: string,
    message: string,
    contentType: connect.ChatMessageContentType
  ) => void;
}

enum LoadStatus {
  Loaded,
  Loading,
  Error,
}

export const ExternalSessionPanel: React.FC<ExternalSessionPanelProps> = (
  props
) => {
  // Local State
  const [expanded, setExpanded] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [activeSession, setActiveSession] = useState<ExternalSession>();
  const [channelInCreation, setChannelInCreation] = useState<boolean>(false);
  const [terminationInProgress, setTerminationInProgress] = useState<boolean>(
    false
  );
  const [loadStatus, setLoadStatus] = useState<LoadStatus>(LoadStatus.Loaded);

  // expand or collapse the controller
  const toggleControllerExpansion = (): void => {
    setExpanded(!expanded);
  };

  // fetch existing external sessions on page load
  const getExternalSessions = async (): Promise<void> => {
    setLoadStatus(LoadStatus.Loading);
    setExpanded(false);
    try {
      const externalSessions = await fetchExternalSessions(
        props.contactInfo.contactId,
        props.contactInfo.originalContactId
      );
      setActiveSession(
        externalSessions.length > 0 ? externalSessions[0] : undefined
      );
      setLoadStatus(LoadStatus.Loaded);
    } catch (error) {
      setLoadStatus(LoadStatus.Error);
    }
  };

  const startExternalSession = async (): Promise<void> => {
    setChannelInCreation(true);
    setErrorMessage(undefined);
    try {
      const session: ExternalSession = await startSession(
        props.contactInfo.contactId
      );
      setActiveSession({ ...session });
      const message = `Please join the remote session using the below link: \n\n ${session.externalSessionKeyUrl!}`;
      props.sendMessage(props.contactInfo.contactId, message, "text/plain");

      const systemMessage: SystemMessage = {
        type: "EXTERNAL_SESSION_START",
      };
      props.sendMessage(
        props.contactInfo.contactId,
        JSON.stringify(systemMessage),
        "application/json"
      );
    } catch (err) {
      const error = err as Error;
      setErrorMessage(error.message);
    }
    setChannelInCreation(false);
  };

  const endExternalSession = async (): Promise<void> => {
    if (activeSession) {
      setTerminationInProgress(true);
      setErrorMessage(undefined);
      try {
        await endSession(
          props.contactInfo.contactId,
          activeSession.startedTimestamp,
          props.contactInfo.originalContactId
        );
        const systemMessage: SystemMessage = {
          type: "EXTERNAL_SESSION_END",
        };
        props.sendMessage(
          props.contactInfo.contactId,
          JSON.stringify(systemMessage),
          "application/json"
        );
        setActiveSession(undefined);
      } catch (err) {
        setErrorMessage(`Failed to end the active session.`);
      }
      setTerminationInProgress(false);
    }
  };

  // ends an external session
  const endActiveExternalSession = (): void => {
    void endExternalSession();
  };

  // fetch external sessions only when the component loads
  useEffect(() => {
    void getExternalSessions();
  }, [props.contactInfo.contactId]);

  return (
    <div className={expanded ? "expanded-container" : "collapsed-container"}>
      {loadStatus === LoadStatus.Loading && (
        <div className="title-container">
          <SpaceBetween direction="horizontal" size="xxs">
            <div className="spinner">
              <Spinner variant="inverted"></Spinner>
            </div>
            <div className="container-title">Loading external sessions...</div>
          </SpaceBetween>
        </div>
      )}
      {loadStatus === LoadStatus.Error && (
        <div className="title-container">
          <SpaceBetween direction="horizontal" size="xxs">
            <div className="error-icon">
              <StatusIndicator type="error"></StatusIndicator>
            </div>
            <div className="container-title">
              Failed to load external sessions.
            </div>
            <Button
              iconName="refresh"
              variant="icon"
              className="awsui-visual-refresh awsui-polaris-dark-mode retry"
              onClick={() => void getExternalSessions()}
            />
          </SpaceBetween>
        </div>
      )}
      {loadStatus === LoadStatus.Loaded && (
        <div className="title-container" onClick={toggleControllerExpansion}>
          <SpaceBetween direction="horizontal" size="xxs">
            <Button
              className="awsui-visual-refresh awsui-polaris-dark-mode"
              iconName={expanded ? "caret-down-filled" : "caret-right-filled"}
              variant="icon"
            />
            <div className="container-title">
              {activeSession
                ? `End the active session`
                : "Start an external session"}
            </div>
          </SpaceBetween>
        </div>
      )}
      {expanded && (
        <div className="button-container">
          {activeSession && (
            <Button
              onClick={endActiveExternalSession}
              className="awsui-visual-refresh awsui-polaris-dark-mode end-session-btn"
              disabled={terminationInProgress}
              loading={terminationInProgress}
            >
              End session
            </Button>
          )}
          {!activeSession && (
            <div>
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  onClick={() => void startExternalSession()}
                  className={`awsui-visual-refresh awsui-polaris-dark-mode ${"BOMGAR".toLowerCase()}-btn`}
                  disabled={channelInCreation}
                  loading={channelInCreation}
                  key={"BOMGAR"}
                >
                  Bomgar
                </Button>
              </SpaceBetween>
            </div>
          )}
        </div>
      )}
      {errorMessage && expanded && (
        <div className="message-container">
          <StatusIndicator type="error"></StatusIndicator>
          <div className="error-message">{errorMessage}</div>
        </div>
      )}
    </div>
  );
};
