export const OMNIA_PROD_URL = "omnia.it.a2z.com";

/* @VisibleForTesting */
export const OMNIA_GAMMA_URL = "gamma.omnia.it.a2z.com";

/* @VisibleForTesting */
export const OMNIA_BETA_URL = "beta.omnia.it.a2z.com";

export const OMNIA_DEV_URL = "dev-dsk.omnia.it.a2z.com";

export const CONNECT_DEV_URL = "dev-dsk.connect.it.a2z.com";

export const CONNECT_PROD_URL = "connect.it.a2z.com";

export const CONNECT_BETA_URL = "beta.connect.it.a2z.com";

export const CONNECT_API_BETA_URL = "https://api.beta.connect.it.a2z.com";

export const CONNECT_API_PROD_URL = "https://api.connect.it.a2z.com";

export const ASSIST_SURVEY_PROD_URL =
  "https://share.amazon.com/sites/Assist%20Feedback%20Form/Lists/Assist%20Form%20%20GSD2%20to%20GSD1/Item/newifs.aspx";

export const ASSIST_SURVEY_TEST_URL = "https://example.com/";

export const QUITANIEVES_PROD_ENDPOINT = "quitanieves.it.a2z.com/collector";

export const QUITANIEVES_BETA_ENDPOINT =
  "beta.quitanieves.it.a2z.com/collector";

export const CHAT_ESCALATION_QUEUE = "its_eng_chat_escalation";

export interface EnvConfig<T> {
  prodValue: T;
  gammaValue: T;
  betaValue: T;
  devValue?: T;
}

/**
 * Gets a configuration value depending on the environment stage.
 * @param prodValue The value for production
 * @param gammaValue The value for gamma
 * @param betaValue The value for beta
 * @param devValue The value for development
 */
function getConfigBySourceUrl<T>(envConfig: EnvConfig<T>) {
  if (
    window.location.hostname === OMNIA_DEV_URL ||
    window.location.hostname === CONNECT_DEV_URL
  ) {
    return envConfig.devValue;
  } else if (
    window.location.hostname === OMNIA_BETA_URL ||
    window.location.hostname === CONNECT_BETA_URL
  ) {
    return envConfig.betaValue;
  } else if (window.location.hostname === OMNIA_GAMMA_URL) {
    return envConfig.gammaValue;
  } else if (
    window.location.hostname === OMNIA_PROD_URL ||
    window.location.hostname === CONNECT_PROD_URL
  ) {
    return envConfig.prodValue;
  } else {
    return false;
  }
}

export const enableChatLiveliness = () => {
  return getConfigBySourceUrl({
    prodValue: false,
    gammaValue: false,
    betaValue: true,
    devValue: true,
  } as EnvConfig<boolean>);
};

export const enableTransferCfmWidget = () => {
  return getConfigBySourceUrl({
    prodValue: true,
    gammaValue: true,
    betaValue: true,
    devValue: true,
  } as EnvConfig<boolean>);
};

export const quitanievesEndpoint = () => {
  return getConfigBySourceUrl({
    prodValue: QUITANIEVES_PROD_ENDPOINT,
    gammaValue: QUITANIEVES_PROD_ENDPOINT,
    betaValue: QUITANIEVES_BETA_ENDPOINT,
    devValue: QUITANIEVES_BETA_ENDPOINT,
  } as EnvConfig<string>) as string;
};
